<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3 product-grid mt-2 mb-2" v-for="event in allEvents.data" :key="event.id">
        <a :href="event.url"><img v-if="event.image[0]" :src="'https://savershall.com/suadmin/'+event.image" alt=""/></a>
          <a :href="event.url" class="social_product_cover">
            <div class="content">
              <span>Shop Now</span>
            </div>
          </a>
      </div>
    </div>
    <nav>
      <paginate
          :page-count=allEvents.last_page
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'page-link'"
      >
      </paginate>
    </nav>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      allEvents: [],
    }
  },
  mounted() {
    axios.get(`https://savershall.com/sh_backapi/api/social-event`).then((response) => {
      this.allEvents = response.data.data
    })
  },
  methods: {
    clickCallback(pageNum) {
      axios.get(`https://savershall.com/sh_backapi/api/social-event?page=` + pageNum).then((response) => {
        this.allEvents = response.data.data
      })
    }
  }
};
</script>
<style>
.col-md-3.product-grid img {
  border-radius: 8px 8px 0px 0px;
  transition: 0.5ms;
}
a.social_product_cover {
  background: #65c03a;
  display: block;
  padding: 10px 0px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0px 0px 8px 8px;
  transition: 0.5ms;
}
.product-grid:hover a.social_product_cover{
  background: #ff6c25;
}
/* pagination */

.pagination {
  height: 36px;
  margin: 18px 0;
  color: #1F1F21FF;
}

.pagination ul {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  margin-left: 0;
  color: #ffffff;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination li {
  display: inline;
  color: #1F1F21FF;
}

.pagination a {
  float: left;
  padding: 0 14px;
  line-height: 34px;
  color: #1f1f21;
  text-decoration: none;
  border: 1px solid #ddd;
  border-left-width: 0;
}

.pagination a:hover,
.pagination .active a {
  background-color: #65c03a;
  color: #ffffff;
}

.pagination a:focus {
  background-color: #1F1F21FF;
  color: #ffffff;
}


.pagination .active a {
  color: #ffffff;
  cursor: default;
}

.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
  color: #999999;
  background-color: transparent;
  cursor: default;
}

.pagination li:first-child a {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.pagination li:last-child a {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
}

.pager {
  margin-left: 0;
  margin-bottom: 18px;
  list-style: none;
  text-align: center;
  color: #1F1F21FF;
  *zoom: 1;
}

.pager:before,
.pager:after {
  display: table;
  content: "";
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
  color: #1F1F21FF;
}

.pager a {
  display: inline-block;
  padding: 5px 14px;
  color: #1F1F21FF;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.pager a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}

.pager .next a {
  float: right;
}

.pager .previous a {
  float: left;
}

.pager .disabled a,
.pager .disabled a:hover {
  color: #999999;
}
</style>